// Variables

$bg-color: #bfbfbf !default;
$theme-color-1:#022e6b !default;
$theme-color-2:#001957 !default;
$theme-color-3:#ffffff !default;
$theme-color-4:#ffa500 !default;
$theme-color-5:#e6f0f8 !default;
$theme-color-6:#00008f !default;
$theme-color-7:#30a9dc !default;
$theme-color-8:#000000 !default;
$text-color: $theme-color-1;
$border-color: #ddd !default; 


$theme-green-color-1: #006a4d !default;
$theme-green-color-2: #c3e0d8 !default;

$theme-gray-color-1: #666666 !default;
$theme-gray-color-2: #ededed !default;
$theme-gray-color-3: #d50109 !default;

$theme-blue-color-1: #4c6476 !default;
$theme-blue-color-2: #cfe2f0 !default;


a {
  color: $theme-color-1;
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    color: $theme-color-1;
    text-decoration: none;

    @include media-breakpoint-up(md) {
      color: $theme-color-4;
    }
  }
}

html {
  font-size: 10px;

  @include media-breakpoint-up(lg) {
    font-size: 8px;
  }

  @media (min-width: 1700px) {
    font-size: 10px;
  }
}

body {
  font-family: 'Montserrat', Arial, sans-serif;
  background: $bg-color;
  padding: 0;
}

.page-bg {
  background: linear-gradient(to bottom, $theme-color-3 0%, $theme-color-3 70%, $bg-color 100%);
  overflow: hidden;
  color: $text-color;
}

.container {
  max-width: 100%;

  @include media-breakpoint-up(md) {
    padding: 0 4rem;
  }

  @include media-breakpoint-up(xl) {
    padding: 0 6.5rem 3rem;
  }
}

.header {

  &__contact {
    font-weight: 700;
    font-size: 1.8rem;
    letter-spacing: 0.15rem;
    text-align: center;

    @media (min-width: 420px) {
      font-size: 2.2rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: center;
      font-size: 2.5rem;
    }

    &-container {
      display: inline-block;
      text-align: left;
      position: relative;
      padding-left: 5rem;

      @include media-breakpoint-up(sm) {
        padding-left: 6rem;
      }

      @include media-breakpoint-up(lg) {
        padding-left: 0;
      }
    }

    span {
      display: block;

      @include media-breakpoint-up(lg) {
        display: inline-block;
      }
    }

    a {
      display: inline-block;
      margin: auto;

      @include media-breakpoint-up(lg) {
        margin-left: 1rem;
      }
      
      svg {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 3rem;

        @include media-breakpoint-up(sm) {
          width: 4rem;
        }

        @include media-breakpoint-up(lg) {
          position: relative;
          width: 3rem;
          height: auto;
          margin: 0 1rem 0 0;
        }

        path {
          transition: 0.3s;
        }
      }

      &:hover {
        svg {
          path {
            @include media-breakpoint-up(md) {
              fill: $theme-color-4;
            }
          }
        }
      }
    }
  }

  &__flag {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.1em;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    &::after {
      content: '';
      width: 7rem;
      height: 4.5rem;
      background-size: cover;
      background-position: 100% 50%;
      background-repeat: no-repeat;
      margin-left: 1.5rem;
    }

    &--de {
      &::after {
        background-image: url(../img/de-flag.png);
      }
    }
    
    &--no {
      &::after {
        background-image: url(../img/no-flag.png);
      }
    }
  }
}

.content {
  &__form {
    background-color: $theme-color-2;
    padding: 4rem 2rem;

    @include media-breakpoint-up(md) {
      padding: 4rem 3rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 6.5rem 4.5rem 4.5rem;
    }

    h2 {
      font-size: 3rem;
      font-weight: 700;
      color: $theme-color-3;
      margin: 0 0 4rem 0;

      @include media-breakpoint-up(md) {
        font-size: 3.5rem;
        margin: 0 0 2rem;
      }
    }

    .form-group {
      margin-bottom: 2rem;
    }

    input {
      width: 100%;
      padding: 1.5rem 2rem;
      background-color: $theme-color-5;
      color: $theme-color-6;
      border: 0;
      outline: 0;
      transition: 0.3s;
      font-size: 1.3rem;
      font-weight: 500;
      box-shadow: 0 0 10px $theme-color-8;

      &::placeholder {
        color: $theme-color-6;
      }

      &:focus {
        outline: 2px solid $theme-color-4;

        &::placeholder {
          color: rgba($theme-color-6,0.4);
        }
      }
    }

    .form-checkbox {
      background-color: $theme-color-5;
      padding: 1.5rem 1rem;
      display: flex;
      justify-content: space-between;
      position: relative;
      box-shadow: 0 0 10px $theme-color-8;
    
      &__input {
        &:not(:checked),
        &:checked {
          position: absolute;
          top: 0;
          left: -9999px;
          opacity: 0;
          width: 2rem;
          height: 2rem;
        }
  
        &:not(:checked) + label,
        &:checked + label {
          position: relative;
          padding-left: 3rem;
          min-height: 2rem;
          cursor: pointer;
        }
  
        /* checkbox aspect */
        &:not(:checked) + label:before,
        &:checked + label:before {
          content: '';
          position: absolute;
          left: 0; 
          top: 0;
          width: 2rem; 
          height: 2rem;
          background: $theme-color-3;
          border: 2px solid $theme-color-3;
          border-radius: 0;
          transition: 0.3s;
          box-shadow: 0 0 5px rgba($theme-color-8, 0.5);
        }
  
        /* checked mark aspect */
        &:not(:checked) + label:after,
        &:checked + label:after {
          content: '';
          position: absolute;
          top: 0; 
          left: 0.5rem;
          line-height: 1em;
          color: $theme-color-1;
          transition: 0.3s;
          width: 1rem;
          height: 1.5rem;
          border-bottom: 3px solid $theme-color-1;
          border-right: 3px solid $theme-color-1;
        }
  
        /* checked mark aspect changes */
        &:not(:checked) + label:after {
          opacity: 0;
          transform: scale(0) rotate(45deg);
        }
  
        &:checked + label:after {
          opacity: 1;
          transform: scale(1) rotate(45deg);
        }
  
        /* hover style just for information */
        
      }
  
      &__label{
        font-size: 1.1rem;
        line-height: 1.1em;

        &:hover:before {
          border-color: $theme-color-4 !important;
        }
      }
    }

    .form-required {
      color: red;
      padding: 0 0.5rem 0 0;
      font-size: 1.3rem;
      position: absolute;
      top: -0.2rem;
      left: 2.5rem;
    }

    &-success,
    &-error {
      display: none;
      margin: 1rem 0;
      color: $theme-color-3;
      font-size: 1.6rem;
      padding: 2rem;
    }

    &-success {
      background-color: green;
    }

    &-error {
      background-color: red;
    }

    .btn-primary {
      border-radius: 0;
      width: 100%;
      padding: 1rem;
      outline: 0;
      border: 2px solid $theme-color-3;
      background-color: $theme-color-4;
      font-size: 1.4rem;
      text-transform: uppercase;
      font-weight: 700;
      transition: 0.3s;

      &:hover {
        background-color: $theme-color-7;
        border-color: $theme-color-7;
        color: $theme-color-3;
      }
    }
  }

  &__info {
    padding: 4rem 2rem;

    @include media-breakpoint-up(lg) {
      padding: 4.5rem 0 0 4.5rem;
    }

    h3 {
      font-size: 2.2rem;
      font-weight: 700;
    }

    h1 {
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      margin: 0 0 1rem;
      font-size: 2.2rem;

      @include media-breakpoint-up(sm) {
        font-size: 3rem;
      }
    }

    h2 {
      text-align: center;
      font-weight: 400;
      font-size: 2.2rem;

      @include media-breakpoint-up(sm) {
        font-size: 3rem;
      }

      &.mb-8rem {
        margin-bottom: 8rem;
      }
    }

    .font-10 {
      font-size: 2rem;
    }

    .font-11 {
      font-size: 2.2rem;
    }

    .font-12 {
      font-size: 2.4rem;
    }
    
    ul {
      list-style-type: square;
      padding-left: 2.4rem;
    }

    &-list--icons {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: 4rem 0 0;
      position: relative;
      list-style-type: none !important;
      padding-left: 0 !important;

      @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
        margin: 7rem 0 0;
      }

      li {
        padding: 10rem 2.5rem 2rem;
        font-size: 2rem;
        text-align: center;
        position: relative;
        width: 100%;

        @include media-breakpoint-up(md) {
          width: unset;
          position: unset;
        }

        &:nth-child(1) {
          background: url(../img/icon-risk.png) no-repeat 50% 0;
          background-size: 15rem auto;
        }

        &:nth-child(2) {
          background: url(../img/icon-form.png) no-repeat 50% 7rem;
          padding: 17rem 2.5rem 8rem;
          background-size: 15rem auto;

          @include media-breakpoint-up(md) {
            background: url(../img/icon-form.png) no-repeat 50% 0;
            background-size: 15rem auto;
            padding: 10rem 2.5rem 2rem;
          }

          &::before,
          &::after {
            content: '';
            width: 5rem;
            height: 5rem;
            display: block;
            position: absolute;
            margin: auto;
            background: url(../img/icon-arrow.png) no-repeat 50% 50%;
            transform: rotate(90deg);

            @include media-breakpoint-up(md) {
              transform: none;
            }
          }

          &::before {
            top: 0;
            left: 0;
            right: 0;

            @include media-breakpoint-up(md) {
              top: 3rem;
              left: 26%;
              right: unset;
            }
          }

          &::after {
            bottom: 1rem;
            left: 0;
            right: 0;

            @include media-breakpoint-up(md) {
              top: 3rem;
              right: 30%;
              left: unset;
              bottom: unset;
            }
          }
        }

        &:nth-child(3) {
          background: url(../img/icon-safety.png) no-repeat 50% 0;
          background-size: 15rem auto;
        }

        strong {
          display: block;
          font-size: 2.4rem;
          margin-top: 2rem;
          text-transform: uppercase;
          font-weight: 700;
        }
        
      }
    }

    &-list--text {
      font-size: 2rem;
      padding: 4rem 0 55vw 2.5rem;
      list-style-type: square;
      background: url(../img/car.png) no-repeat right 0 bottom 10%;
      background-size: 90% auto;
      border-bottom: 0.3rem solid $theme-color-2;

      @include media-breakpoint-up(sm) {
        font-size: 2.4rem;
      }

      @include media-breakpoint-up(md) {
        padding: 4rem 0 50vw 2.5rem;
        background-size: 90% auto;
      }

      @include media-breakpoint-up(lg) {
        padding: 4rem 0 30vw 2.5rem;
      }

      @media (min-width: 1280px) {
        padding: 5rem 0 13vw 2.5rem;
        background: url(../img/car.png) no-repeat right 0 bottom 3rem;
        background-size: 35vw auto;
      }

      li {
        padding: 0;
        margin: 2.5rem 0;
        font-weight: 500;
      }
    }

    p {
      font-size: 2rem;
      margin: 3rem 0;
      line-height: 1.2em;

      @include media-breakpoint-up(sm) {
        font-size: 2.4rem;
      }

      @include media-breakpoint-up(lg) {
        margin: 5rem 0;
      }

      &.text-small {
        font-size: 1.3rem;
      }
    }

    &-list--asterisks {
      font-size: 2rem;
      padding: 0;
      padding-left: 0 !important;
      margin: 3rem 0;
      line-height: 1.3em;

      @include media-breakpoint-up(sm) {
        font-size: 2.4rem;
      }

      @include media-breakpoint-up(lg) {
        margin: 5rem 0;
      }

      li {
        list-style: none;
        position: relative;
        padding: 0 0 0 4.5rem;

        @include media-breakpoint-up(sm) {
          padding: 0 0 0 5.5rem;
        }

        &::before {
          content: '*****';
          width: 4rem;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .sliders {
      align-items: center;
      padding: 2.5rem 0;

      p {
        font-size: 2.2rem;
        margin: 0;
      }

      .slider {
        width: 100%;

        &.slider-horizontal {
          height: 4.4rem;

          .slider-track {
            height: 6px;
            margin-top: -3px;
            border-radius: 0;
  
            .slider-selection {
              border-radius: 0;
              background: $border-color;
            }
  
            .slider-track-low,
            .slider-track-high {
              border-radius: 0;
            }
          }
        }

        .slider-handle {
          width: 4.4rem;
          height: 4.4rem;
          margin-left: -2.2rem;
          border-radius: 50%;
          border: 4px solid $border-color;
          background: $border-color;
        }
      }

      .sliders-min {
        float: left;
      }

      .sliders-max {
        float: right;
      }

      .sliders-val {
        display: inline-block;
        width: 18rem;
        padding: 2rem 0;
        text-align: center;
        border: 1px solid $border-color;
        background: rgba($border-color,0.5);
        font-size: 2.2rem;
        font-weight: 700;
      }
    }
  }
}

.theme-green {
  color: $theme-green-color-1;

  a {
    color: $theme-green-color-1;

    &:hover {
      color: $theme-green-color-1;

      @include media-breakpoint-up(md) {
        color: $theme-color-4;
      }
    }
  }

  .header__contact {
    a {
      svg {
        path {
          fill: $theme-green-color-1;
        }
      }

      &:hover {
        svg {
          path {
            fill: $theme-color-4;
          }
        }
      }
    }
  }

  .content__form {
    background-color: $theme-green-color-1;

    input {
      color: $theme-green-color-1;
      background-color: $theme-green-color-2;

      &::placeholder {
        color: $theme-green-color-1;
      }

      &:focus {
        outline: 2px solid $theme-color-4;

        &::placeholder {
          color: rgba($theme-green-color-1,0.4);
        }
      }
    }

    .form-checkbox {
      background-color: $theme-green-color-2;

      &__input:checked + label:after, 
      &__input:not(:checked) + label:after {
        border-bottom: 3px solid $theme-green-color-1;
        border-right: 3px solid $theme-green-color-1;
      }
    }

    .btn-primary:hover {
      background-color: darken($theme-green-color-1,7%);
      border-color: darken($theme-green-color-1,7%);
    }
  }

  .content__info {
    .sliders {
      .slider {
        &.slider-horizontal {
          .slider-track {
            .slider-selection {
              background: $theme-green-color-1;
            }
          }
        }
        .slider-handle {
          border-color: $theme-green-color-1;
        }
      }

      .sliders-val {
        border-color: $theme-green-color-1;
      }
    }
  }

  hr {
    border: 0;
    border-top: 3px solid $theme-green-color-1;
    margin: 0;
  }
}

.theme-gray {
  color: $theme-gray-color-1;

  a {
    color: $theme-gray-color-1;

    &:hover {
      color: $theme-gray-color-1;

      @include media-breakpoint-up(md) {
        color: $theme-color-4;
      }
    }
  }

  .header__contact {
    a {
      svg {
        path {
          fill: $theme-gray-color-1;
        }
      }

      &:hover {
        svg {
          path {
            fill: $theme-color-4;
          }
        }
      }
    }
  }

  .content__form {
    background-color: $theme-gray-color-1;

    input {
      color: $theme-gray-color-1;
      background-color: $theme-gray-color-2;

      &::placeholder {
        color: $theme-gray-color-1;
      }

      &:focus {
        outline: 2px solid $theme-color-4;

        &::placeholder {
          color: rgba($theme-gray-color-1,0.4);
        }
      }
    }

    .form-checkbox {
      background-color: $theme-gray-color-2;

      &__input:checked + label:after, 
      &__input:not(:checked) + label:after {
        border-bottom: 3px solid $theme-gray-color-1;
        border-right: 3px solid $theme-gray-color-1;
      }
    }

    .btn-primary:hover {
      background-color: darken($theme-gray-color-1,7%);
      border-color: darken($theme-gray-color-1,7%);
    }
  }

  .content__info {
    h1,
    h2 {
      color: $theme-gray-color-3;
    }

    &-list--icons {
      li {
        &:nth-child(1) {
          background-image: url(../img/icon-risk-red.png);
        }

        &:nth-child(2) {
          background-image: url(../img/icon-form-red.png);

          &::before,
          &::after {
            background-image: url(../img/icon-arrow-gray.png);
          }
        }

        &:nth-child(3) {
          background-image: url(../img/icon-safety-red.png);
        }        
      }
    }

    .sliders {
      .slider {
        &.slider-horizontal {
          .slider-track {
            .slider-selection {
              background: $theme-gray-color-1;
            }
          }
        }
        .slider-handle {
          border-color: $theme-gray-color-1;
        }
      }

      .sliders-val {
        border-color: $theme-gray-color-1;
      }
    }

    &-list--text {
      border-bottom-color: $theme-gray-color-1;
      background-image: url(../img/insurance.png);
      background-position: right 0 bottom 0;
      padding: 4rem 0 40vw 2.5rem;

      @include media-breakpoint-up(md) {
        padding: 4rem 0 10vw 2.5rem;
        background-size: auto 80%;
      }

      @media (min-width: 1280px) {
        padding: 5rem 0 8rem 2.5rem;
        background-size: auto 100%;
      }

      li {
        margin: 1rem 0;
      }
    }

    
  }

  hr {
    border: 0;
    border-top: 3px solid $theme-gray-color-1;
    margin: 0;
  }
}

.theme-blue {
  color: $theme-blue-color-1;

  a {
    color: $theme-blue-color-1;

    &:hover {
      color: $theme-blue-color-1;

      @include media-breakpoint-up(md) {
        color: $theme-color-4;
      }
    }
  }

  .header__contact {
    a {
      svg {
        path {
          fill: $theme-blue-color-1;
        }
      }

      &:hover {
        svg {
          path {
            fill: $theme-color-4;
          }
        }
      }
    }
  }

  .content__form {
    background-color: $theme-blue-color-1;

    input {
      color: $theme-blue-color-1;
      background-color: $theme-blue-color-2;

      &::placeholder {
        color: $theme-blue-color-1;
      }

      &:focus {
        outline: 2px solid $theme-color-4;

        &::placeholder {
          color: rgba($theme-blue-color-1,0.4);
        }
      }
    }

    .form-checkbox {
      background-color: $theme-blue-color-2;

      &__input:checked + label:after, 
      &__input:not(:checked) + label:after {
        border-bottom: 3px solid $theme-blue-color-1;
        border-right: 3px solid $theme-blue-color-1;
      }
    }

    .btn-primary:hover {
      background-color: darken($theme-blue-color-1,7%);
      border-color: darken($theme-blue-color-1,7%);
    }
  }

  .content__info {
    .sliders {
      .slider {
        &.slider-horizontal {
          .slider-track {
            .slider-selection {
              background: $theme-blue-color-1;
            }
          }
        }
        .slider-handle {
          border-color: $theme-blue-color-1;
        }
      }

      .sliders-val {
        border-color: $theme-blue-color-1;
      }
    }
  }

  hr {
    border: 0;
    border-top: 3px solid $theme-blue-color-1;
    margin: 0;
  }
}